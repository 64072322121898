<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" no-gutters>
			<v-col cols="12" :sm="step === 2 ? '11' : '12'">
				<base-heading :title="title">
					<base-btn v-if="showMainReport" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
			</v-col>
			<v-col cols="12" sm="1" align="center">
        <base-btn v-if="step === 2" color="grey darken-3" text @click="close" icon="mdi-arrow-left" btnText="Atras"></base-btn>
      </v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<v-window v-model="step" touchless>
					<v-window-item :value="1">
						<base-data-table 
							v-model="sortBy"
							desc
							:headers="headers" 
							:items="purchases" 
							@loadDataTable="toList"
							@newItem="step++"
						>
							<template v-slot:item.options="{ item }">
								<base-icon-btn color="info darken-1" @click="viewItem(item)" icon="mdi-eye" tooltip="Ver Detalle"></base-icon-btn>
								<base-icon-btn v-if="item.canCancel" color="red darken-1" @click="openPurchaseActionDialog(item, $Const.STATUS.CANCELLED)" icon="mdi-cancel" tooltip="Anular"></base-icon-btn>
								<base-icon-btn v-if="item.statusId!==$Const.STATUS.CANCELLED" color="green darken-1" @click="reportView(item)" icon="mdi-file-chart" tooltip="Ver Informe"></base-icon-btn>
								<base-icon-btn v-if="item.statusId===$Const.STATUS.REGISTERED" color="success" @click="openPurchaseActionDialog(item, $Const.STATUS.APPROVED)" icon="mdi-check-bold" tooltip="Aprobar"></base-icon-btn>
							</template>

							<template v-slot:item.status="{ item }">
								<template v-if="item.statusId===$Const.STATUS.REGISTERED">
									<span class="blue--text">{{item.status}}</span>
								</template>
								<template v-else-if="item.statusId===$Const.STATUS.APPROVED">
									<span class="success--text">{{item.status}}</span>
								</template>
								<template v-else>
									<span class="red--text">{{item.status}}</span>
								</template>
							</template>

							<template v-slot:item.paymentType="{ item }">
								<base-icon v-if="item.paymentType == 1" :color="item.statusId===$Const.STATUS.CANCELLED ? 'red' : 'green darken-1'" tooltip="Efectivo">mdi-cash</base-icon>
								<base-icon v-else-if="item.paymentType === 2" :color="item.statusId===$Const.STATUS.CANCELLED ? 'red' : 'info darken-1'" tooltip="Tajeta">mdi-credit-card-outline</base-icon>
								<template v-else-if="item.paymentType === 3">
									<base-icon v-if="item.creditStatusId===$Const.STATUS.CREDIT_PENDING" color="secondary" :tooltip="item.creditStatus">mdi-account-cash</base-icon>
									<base-icon v-else-if="item.creditStatusId===$Const.STATUS.CREDIT_COMPLETED" color="green darken-1" :tooltip="item.creditStatus">mdi-account-cash</base-icon>
									<base-icon v-else-if="item.creditStatusId===$Const.STATUS.CANCELLED" color="red" :tooltip="`Crédito ${item.creditStatus}`">mdi-account-cash</base-icon>
								</template>
							</template>

							<template v-slot:item.dateTime="{ item }">
								<base-form-date-list :dateTimeToShow="item.dateTime" 
									:showDialog="item.showDateTimeDialog"
									:formTitle="`Información compra: ${item.id}`"
									:headers="dateTimeHeaders"
									:items="item.dateTimeItems"
									@open="openDateTimeDialog(item)"
									@close="closeDateTimeDialog(item)"
								></base-form-date-list>
							</template>
						</base-data-table>

						<v-dialog v-model="showPurchaseActionDialog" persistent scrollable max-width="800px">
							<base-form-simple ref="PurchaseActionForm"
								:formTitle="editedPurchase.title"
								:formLoading="loadingPurchaseActionForm"
								@cancelButton="closePurchaseActionDialog"
								@saveButton="savePurchaseAction"
							>
								<template v-slot:formContent>
									<v-container class="no-padding" fluid>
										<v-row>
											<v-col cols="12" sm="3" md="3">
												<base-text-field v-model="editedPurchase.id" label="Número de Compra" readonly></base-text-field>
											</v-col>
											<v-col cols="12" sm="12" md="12">
												<base-textarea outlined v-model="editedPurchase.reason" label="Motivo" counter maxlength="250" 
												:rules="[commonValRules.required, validationRules.reasonRules.counter]" required></base-textarea>
											</v-col>
										</v-row>
									</v-container>
								</template>
							</base-form-simple>
						</v-dialog>
					</v-window-item>
					<v-window-item :value="2">
						<v-dialog v-model="showProductFinder" persistent scrollable max-width="1000px">
							<base-product-finder mode="Purchases" @cancelButton="closeProductFinder" @addProduct="addDetail" @removeProduct="removeDetailFromFinder" :productsAdded="details.map(x => x.productId)"/>
						</v-dialog>

						<base-form-simple ref="PurchaseForm"
							:formTitle="purchaseFormTitle"
							:formLoading="formLoading"
							:hideSaveButton="viewDetails"
							@cancelButton="close"
							@saveButton="save"
						>
							<template v-slot:formContent>
								<v-container class="no-padding" fluid>
									<v-row justify="center">
										<v-col v-if="showReason" cols="12">
											<v-alert
												text
												elevation="2"	
												color="red"
												transition="scale-transition"
											>
												<v-icon color="red" left>mdi-alert-circle</v-icon>
												<strong>La compra fue anulada por el siguiente motivo: </strong> {{editedItem.reason}}
											</v-alert>
										</v-col>

										<!-- Detalles de la compra -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Datos de la compra"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col v-if="viewDetails" cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.id" label="Nro. de Compra" readonly>
															</base-text-field>
														</v-col>
														<v-col v-if="viewDetails" cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.dateTime" label="Fecha y hora" readonly>
															</base-text-field>
														</v-col>
														<v-col v-if="viewDetails" cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.status" label="Estado" readonly>
															</base-text-field>
														</v-col>

														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-if="viewDetails" v-model="editedItem.voucherType" label="Comprobante" readonly>
															</base-text-field>
															<base-select v-else v-model="editedItem.voucherTypeId" :items="voucherTypes" label="Comprobante" 
															:rules="[commonValRules.required]" required></base-select>
														</v-col>
														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-if="viewDetails" v-model="editedItem.voucherNumber" label="Nro. Comprobante" readonly>
															</base-text-field>
															<base-text-field v-else v-model="editedItem.voucherNumber" clearable label="Nro. Comprobante" placeholder="0" counter maxlength="15"
															:rules="[commonValRules.required, validationRules.voucherNumberRules.counter]" required></base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-if="viewDetails" v-model="editedItem.authorizationNumber" label="Nro. Autorización" readonly>
															</base-text-field>
															<base-text-field v-else v-model="editedItem.authorizationNumber" clearable label="Nro. Autorización" placeholder="0" counter maxlength="20" 
															:rules="authorizationNumberRules" :required="editedItem.voucherTypeId === 1"></base-text-field>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Datos del proveedor -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Datos del proveedor"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row>
														<v-col cols="12" sm="8" md="8">
															<base-text-field v-if="viewDetails" v-model="editedItem.provider" label="Proveedor" readonly>
															</base-text-field>
															<base-select v-else v-model="editedItem.providerId" :items="providers" label="Proveedor" 
															:rules="[commonValRules.required]" required :readonly="viewDetails"></base-select>
														</v-col>
														<v-col cols="12" sm="4" md="4">
															<base-text-field v-if="viewDetails" v-model="editedItem.customCode" label="Código" readonly>
															</base-text-field>
															<base-text-field v-else v-model="editedItem.customCode" clearable label="Código" placeholder="0" counter maxlength="20" 
															:rules="[validationRules.customCodeRules.counter]"></base-text-field>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Detalles de los productos -->
										<v-col cols="12">
											<base-form-container
												title="Productos"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:content>
													<v-row v-if="!viewDetails" dense align="center" justify="center">
														<v-col cols="9" sm="8" md="6" align="center">
															<v-tooltip v-if="$vuetify.breakpoint.mdAndUp" top>
																<template v-slot:activator="{ on }">
																	<base-text-field v-on="on" clearable @keyup.enter="fetchProduct()" v-model="productCode" label="Código Producto" counter maxlength="20" prepend-icon="mdi-cart"
																	:hint="productCodeHint"></base-text-field>
																</template>
																<span>Ingrese el código y presione la tecla 'Enter'</span>
															</v-tooltip>
															<template v-else>
																<base-text-field clearable v-model="productCode" label="Código Producto" counter maxlength="20"
																:hint="productCodeHint"></base-text-field>
															</template>
														</v-col>
														<v-col v-if="$vuetify.breakpoint.smAndDown" cols="3" sm="2" align="center">
															<base-icon-btn fabMode small btnColor="secondary" color="white" @click="fetchProduct()" icon="mdi-cart" tooltip="Agregar" :disabled="!productCodeIsValid"></base-icon-btn>
														</v-col>
														<v-col cols="12" sm="6" md="4" align="center">
															<base-btn color="primary" @click="openProductFinder()" icon="mdi-clipboard-text-search" btnText="Productos"></base-btn>
														</v-col>
													</v-row>
													
													<v-row align="center" justify="center">
														<v-col cols="12" align="center">
															<base-data-table ref="DtDetails"
																:headers="customDetailHeaders" 
																:items="details"
																sortBy="customCode"
																hideHeader
																:show-expand="showExpand"
																item-key="productId"
																elevation="0"
																showBorder
															>
																<template v-if="!viewDetails" v-slot:item.options="{ item }">
																	<base-icon-btn :size="25" min-elevation="0" color="red darken-1" @click="removeDetail(item)" icon="mdi-close" tooltip="Eliminar Producto"></base-icon-btn>
																	<base-icon-btn :size="25" min-elevation="0" color="secondary" @click="updateProduct(item)" icon="mdi-reload" tooltip="Actualizar Producto"></base-icon-btn>
																</template>

																<template v-slot:expanded-item="{ item }">
																	<base-form-stock-details :ref="`StockForm${item.productId}`" :stockFormId="item.stockFormId" :title="`Detalles: ${item.product}`" :details="item.stockDetails" :mode="viewDetails ? 'ShowData' : 'Editable'">
																	</base-form-stock-details>
																</template>

																<template v-slot:item-data-table-expand="{ expand, isExpanded, item }">
																	<transition v-if="item.stockFormId !== $Const.STOCK_FORM.GENERAL" name="fade" mode="out-in">
																		<base-icon-btn v-if="isExpanded" :size="25" min-elevation="0" key="hide" @click="expand(closeStockFormDetails(isExpanded, item.productId))" color="red darken-1" icon="mdi-eye-off" tooltip="Ocultar Detalles"></base-icon-btn>
																		<base-icon-btn v-else :size="25" min-elevation="0" key="show" @click="expand(!isExpanded)" color="success darken-1" icon="mdi-eye" tooltip="Ver Detalles"></base-icon-btn>
																	</transition>
																</template>

																<template v-slot:item.image="{ item }">
																	<div style="width:100px;">
																		<base-product-img v-model="item.image" :productId="item.productId"
																			class="my-2"
																			gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
																			aspect-ratio="1.5"
																			contain
																		>
																		</base-product-img>
																	</div>
																</template>

																<template v-slot:item.quantity="{ item }">
																	<base-number-field v-if="canEditStock(item.stockFormId)" v-model="item.quantity" maxlength="6"
																	prepend-inner-icon="mdi-cart-variant" :rules="[validationRules.quantityRules.counter, validationRules.quantityRules.valid]" required></base-number-field>

																	<base-text-field v-else-if="!viewDetails" type="number" class="centered-input custom-max-width" :value="getStockSum(item)" maxlength="6"
																	hint="Se actualiza desde los detalles"
																	prepend-inner-icon="mdi-cart-variant" :rules="[commonValRules.required, validationRules.quantityRules.counter, validationRules.quantityRules.valid]" required readonly></base-text-field>

																	<template v-else>
																		<v-icon small>mdi-cart-variant</v-icon> {{ item.quantity }}
																	</template>
																</template>

																<template v-slot:item.unitCost="{ item }">
																	<base-currency-field v-if="!viewDetails"
																		v-model="item.unitCost"
																		:rules="[validationRules.unitCostRules.valid]" 
																		required
																	></base-currency-field>

																	<template v-else-if="editedItem.prorate == true">
																		Bs. {{ item.originalUnitCost }}
																	</template>
																	<template v-else>
																		Bs. {{ item.unitCost }}
																	</template>
																</template>

																<template v-slot:item.additionalUnitCost="{ item }">
																	<v-chip
																		color="secondary"
																		dark
																	>
																		<template v-if="!viewDetails">
																			+ {{ additionalUnitCost }}
																		</template>
																		<template v-else>
																			+ {{ item.additionalUnitCost }}
																		</template>
																	</v-chip>
																</template>

																<template v-slot:item.newUnitCost="{ item }">
																	<v-chip
																		color="secondary"
																		dark
																	>
																		<template v-if="!viewDetails">
																			Bs. {{ getNewUnitCost(item.unitCost)}}
																		</template>
																		<template v-else>
																			Bs. {{ item.unitCost }}
																		</template>
																	</v-chip>
																</template>

																<template v-slot:item.totalCost="{ item }">
																	<template v-if="!viewDetails">
																		Bs. {{ item.totalCost=(item.quantity * item.unitCost).toFixed(2) }}
																	</template>
																	<template v-else>
																		Bs. {{ item.totalCost }}
																	</template>
																</template>

																<template v-slot:no-data>
																	<h3 class="red--text">*No hay productos agregados al detalle.</h3>
																</template>
															</base-data-table>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Datos finales -->
										<v-col cols="12" sm="6" order-sm="2" align="right">
											<base-form-container
												title="Datos finales"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:titleContent>
													<v-spacer></v-spacer>
													<v-badge color="red" overlap :content="calculateTotalProductsForBadge" :value="calculateTotalProductsForBadge">
														<v-icon dark>mdi-cart-variant</v-icon>
													</v-badge>
												</template>

												<template v-slot:content>
													<v-row align="center" justify="center" no-gutters>
														<v-col cols="6" sm="6" align="center">
															<h3 class="mt-6 mt-sm-0">
																<strong>Productos en Total</strong>
																<p class="mt-2 mb-0">{{calculateTotalProducts}}</p>
															</h3>
														</v-col>
														<v-col cols="6" sm="6" align="center">
															<h3 class="mt-6 mt-sm-0">
																<strong>Total a Pagar</strong>
																<p class="mt-2 mb-0">Bs. 
																	<template v-if="!viewDetails">
																		{{editedItem.totalCost = calculateTotal}}
																	</template>
																	<template v-else>
																		{{editedItem.totalCost}}
																	</template>
																</p>
															</h3>
														</v-col>
														<v-col cols="12" class="pt-4">
															<base-textarea outlined v-model="editedItem.observations" label="Observaciones" :counter="!viewDetails" maxlength="250" 
															:rules="[validationRules.observationsRules.counter]" :readonly="viewDetails"></base-textarea>
														</v-col>
														<v-col v-if="!viewDetails" cols="12">
															<v-checkbox
																v-model="editedItem.generateVoucher"
																label="Generar Comprobante"
																color="info"
															></v-checkbox>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Gastos adicionales -->
										<v-col cols="12" sm="6" order-sm="1">
											<base-form-container
												title="Gastos adicionales"
												titleClasses="secondary white--text"
											>
												<template v-slot:content>
													<v-form v-if="!viewDetails" ref="AdditionalCostsForm">
														<v-row dense align="center" justify="center">
															<v-col cols="12" sm="6" md="6">
																<base-text-field 
																	label="Descripción"
																	v-model="editedAdditionalCosts.description"
																	clearable 
																	maxlength="20"
																	counter
																	:rules="[commonValRules.required, additionalCostRules.descriptionRules.counter]" 
																	required
																></base-text-field>
															</v-col>
															<v-col align="center" cols="8" sm="4" md="4">
																<base-currency-field
																	label="Costo"
																	v-model="editedAdditionalCosts.cost"
																	:rules="[additionalCostRules.costRules.valid]" 
																	required
																></base-currency-field>
															</v-col>

															<v-col cols="3" sm="2" md="2" align="center">
																<base-icon-btn fabMode small btnColor="primary darken-1" color="white" @click="newAdditionalCost()" icon="mdi-plus-thick" tooltip="Agregar" ></base-icon-btn>
															</v-col>
														</v-row>
													</v-form>

													<v-row>
														<v-col cols="12">
															<base-data-table
																:headers="customAdditionalCostHeaders" 
																:items="additionalCostDetails"
																hideHeader
																elevation="0"
																showBorder
															>
																<template v-slot:item.options="{ item }">
																	<base-icon-btn v-if="!viewDetails" color="red darken-1" @click="removeAdditionalCost(item)" icon="mdi-close" tooltip="Eliminar"></base-icon-btn>
																</template>

																<template v-slot:item.cost="{ item }">
																	<base-currency-field v-if="!viewDetails && item.canEdit"
																		v-model="item.cost"
																		:rules="[additionalCostRules.costRules.valid]" 
																		required
																	></base-currency-field>

																	<div v-else>
																		Bs. {{ item.cost }}
																	</div>
																</template>

																<template v-slot:no-data>
																	Sin gastos adicionales
																</template>
															</base-data-table>
														</v-col>
													</v-row>

													<v-expand-transition>
														<v-row v-if="additionalCostDetails.length > 0" align="center" justify="center">
															<v-col cols="12" sm="10" md="4" align="center">
																<v-switch label="Prorratear el gasto a los productos"
																	v-model="editedItem.prorate"
																	inset
																	:readonly="viewDetails"
																></v-switch>
															</v-col>

															<v-expand-transition>
																<v-col v-if="editedItem.prorate" cols="6" sm="6" md="4" align="center">
																	<h3 class="mt-6 mt-sm-0">
																		<strong>Por Producto</strong><p class="mt-2 mb-0">Bs. {{additionalUnitCost}}</p>
																	</h3>
																</v-col>
															</v-expand-transition>

															<v-col cols="6" sm="6" md="4" align="center">
																<h3 class="mt-6 mt-sm-0">
																	<strong>Total Gastos</strong>
																	<p class="mt-2 mb-0">Bs. 
																		{{additionalTotalCost}}
																	</p>
																</h3>
															</v-col>

															<v-expand-transition>
																<v-col v-if="editedItem.prorate" cols="12">
																	<v-alert
																		text
																		elevation="2"	
																		color="info"
																		class="text-justify"
																	>
																		<p><v-icon color="info" left>mdi-information</v-icon><b>¡IMPORTANTE!</b></p>
																		<p>Habilitando el prorrateo de gastos a los productos, <b>el monto Bs. {{additionalUnitCost}} será sumado al costo unitario del producto para generar un nuevo monto</b>, el cuál, se registrara como el <b>costo unitario final para el sistema.</b></p>
																		No obstante, este nuevo monto generado <b>no afectará al monto total del producto.</b>
																	</v-alert>
																</v-col>
															</v-expand-transition>

														</v-row>
													</v-expand-transition>
												</template>
											</base-form-container>
										</v-col>

										<!-- Formulario de pagos -->
										<v-col cols="12" sm="6" order-sm="3">
											<base-form-payment-type ref="PaymentForm" v-model="paymentData" :totalPrice="editedItem.totalCost" :disabledFields="viewDetails" :status="editedItem.creditStatusId">
											</base-form-payment-type>
										</v-col>
									</v-row>
								</v-container>
							</template>
						</base-form-simple>
					</v-window-item>
				</v-window>
			</v-col>
		</v-row>

		<base-pdf-viewer :show="showPdfViewer" :title="reportFile.title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	data() {
		return {
			title:'Orden de Compra',
			step: 1,
			sortBy: 'dateTime',
			headers: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Nro. Compra", value: "id" },
        { text: "Usuario", value: "userFullName" },
				{ text: "Proveedor", value: "provider" },
				{ text: "Código", value: "customCode" },
				{ text: "Tipo Comprobante", value: "voucherType" },
        { text: "Nro. Comprobante", value: "voucherNumber", sortable: false},
				{ text: "Fecha", value: "dateTime" },
				{ text: "Total Bs.", value: "totalCost", sortable: false},
				{ text: "Tipo Pago", value: "paymentType" },
        { text: "Estado", value: "status" }
			],
			purchases: [],
			detailHeaders: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Imagen", value: "image", sortable: false },
				{ text: "Código", value: "customCode", sortable: false },
				{ text: "Producto", value: "product", sortable: false },
        { text: "Cantidad", value: "quantity", sortable: false },
				{ text: "Costo Unit.", value: "unitCost", sortable: false },
				{ text: "Costo Adicional", value: "additionalUnitCost", sortable: false },
				{ text: "Nuevo Costo Unit.", value: "newUnitCost", sortable: false },
				{ text: "Subtotal", value: "totalCost", sortable: false },
				{ text: 'Detalles', value: 'data-table-expand' },
			],
			details: [],
			showExpand: false,
			voucherTypes:[
        {value: 1, text: "Factura"},
        {value: 2, text: "Recibo"},
        {value: 3, text: "Boleta"},
				{value: 4, text: "Ticket"},
				{value: 5, text: "Nota"}
			],
			providers:[],
			editedItem: {
				id: '',
				providerId:'',
				provider:'',
				customCode: '',
				voucherTypeId:'',
				voucherType:'',
        voucherNumber: '',
				authorizationNumber: '',
				totalCost: 0,
				observations: '',
				dateTime: '',
				generateVoucher: false,
				statusId: 0,
				status: '',
				creditStatusId: 0,
				reason: '',
				hasAdditionalCosts: false,
				prorate: false,
      },
      defaultItem: {
       	id: '',
				providerId:'',
				provider:'',
				customCode: '',
				voucherTypeId:'',
				voucherType:'',
        voucherNumber: '',
				authorizationNumber: '',
				totalCost: 0,
				observations: '',
				dateTime: '',
				generateVoucher: false,
				statusId: 0,
				status: '',
				creditStatusId: 0,
				reason: '',
				hasAdditionalCosts: false,
				prorate: false,
			},
			//PAYMENT DATA
			paymentData: {
				paymentTypeId: 1,
				amountPaid: '',
				cardNumber:'',
				nextPaymentDate:'',
				creditPaymentTypeId: 1
			},
			//PRODUCT
			productCode: '',
			productCodeIsValid:false,
			showProductFinder:false,

			formLoading: false,
			viewDetails:false,
			//ADDITIONAL COSTS
			editedAdditionalCosts: {
				description: '',
				cost: ''
			},
			defaultAdditionalCosts: {
				description: '',
				cost: ''
			},

			additionalCostHeaders: [
				{ text: "Eliminar", value: "options", sortable: false },
				{ text: "Descripción", value: "description", sortable: false },
        { text: "Costo", value: "cost", sortable: false },
			],
			additionalCostDetails:[],

			additionalCostRules: {
				descriptionRules: {
          counter: value => (!!value && value.length >= 4 && value.length <= 20) || 'No debe tener más de 20 caracteres, ni menos de 4 caracteres.',
				},

				costRules: {
					valid: value => (parseFloat(value) > 0) || 'No puede ser menor a 1.',
				}
			},

			//PURCHASE DATE TIMES
			dateTimeHeaders: [
				{ text: "Nro", value: "row", sortable: false },
				{ text: "Usuario", value: "userFullName", sortable: false },
				{ text: "Fecha y hora", value: "dateTime", sortable: false },
        { text: "Estado", value: "status", sortable: false },
			],

			//INVENTORY MOVEMENT ACTION
			editedPurchase: {
				title: '',
				id: '',
				reason: '',
				nextStatusId: 0
      },
      defaultPurchase: {
				title: '',
       	id: '',
				reason: '',
				nextStatusId: 0
			},
			showPurchaseActionDialog: false,
			loadingPurchaseActionForm: false,

      validationRules: {
				customCodeRules: {
          counter: value => (value != null && value.length <= 20) || 'No debe tener más de 20 caracteres.',
				},

        voucherNumberRules: {
          counter: value => (value != null && value.length <= 15) || 'No debe tener más de 15 caracteres.',
				},
				authorizationNumberRules: {
          counter: value => (value != null && value.length <= 20) || 'No debe tener más de 20 caracteres.',
				},
				observationsRules: {
          counter: value => (value.length <= 250) || 'No debe tener más de 250 caracteres.',
				},
				
				productCodeRules: {
					counter: value => (!!value && value.length >= 4 && value.length <= 20) || 'No debe tener más de 20 caracteres, ni menos de 4 caracteres.',
				},

				quantityRules: {
					counter: value => (value != null && value.toString().length <= 6) || 'No debe tener más de 6 caracteres.',
          valid: value => (parseInt(value) > 0) || 'No puede ser menor a 1.',
				},
				unitCostRules: {
          valid: value => (parseFloat(value) > 0) || 'No puede ser menor a 1.',
				},

				reasonRules: {
					counter: value => (!!value && value.length >= 10 && value.length <= 250) || 'No debe tener más de 250 caracteres, ni menos de 10 caracteres.',
				},
			},
			
			//PDF comprobante
			showPdfViewer: false,
			reportFile:{
				title: '',
				purchaseId:'',
				fileName:'',
				fileContent:''
			},
			defaultReportFile:{
				title: '',
				purchaseId:'',
				fileName:'',
				fileContent:''
			},
		}
	},

	computed: {
		purchaseFormTitle() {
			return this.viewDetails ? 'Compra' : 'Nueva compra';
		},

		showMainReport(){
			if (this.step === 2 || this.purchases.length<=0) {
				return false;	
			}
			return true;
		},

		customDetailHeaders(){
			var excludes = [];
			if (this.viewDetails) {
				excludes.push('options');
			}

			this.showExpand = true;
			if (!this.details.some(x => x.stockFormId != this.$Const.STOCK_FORM.GENERAL)) {
				this.showExpand = false;
				excludes.push('data-table-expand');
			}

			if (!this.editedItem.prorate) {
				excludes.push('additionalUnitCost');
				excludes.push('newUnitCost');
			}

			if (excludes.length <= 0) {
				return this.detailHeaders;
			}
			return this.detailHeaders.filter(x => !excludes.includes(x.value));
		},

		customAdditionalCostHeaders(){
			if (this.viewDetails) {
				let result = this.additionalCostHeaders.filter(x => x.value != 'options');
				return result;
			}
			else{
				return this.additionalCostHeaders;
			}
		},

		productCodeHint(){
			var result = this.validationRules.productCodeRules.counter(this.productCode);
			if (result == true) {
				this.productCodeIsValid = true;
				return '';
			}
			else {
				this.productCodeIsValid = false;
				return result;
			}
		},

		calculateTotalProducts(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? result : 0;
		},

		calculateTotalProductsForBadge(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? (result > 99 ? result = '+99' : result) : 0;
		},

		calculateTotal(){
			var result=0.0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result=result + (element.unitCost * element.quantity);
			}
			return parseFloat(result).toFixed(2);
		},

		authorizationNumberRules(){
			var rules=[];
			if (this.editedItem.voucherTypeId === 1) {
				rules.push(this.commonValRules.required);
			}
			rules.push(this.validationRules.authorizationNumberRules.counter);
			return rules;
		},

		additionalTotalCost(){
			var result=0.0;
			for (let i = 0; i < this.additionalCostDetails.length; i++) {
				const element = this.additionalCostDetails[i];
				result = result + parseFloat(element.cost);
			}
			return parseFloat(result).toFixed(2);
		},

		additionalUnitCost() {
			if (this.additionalTotalCost <= 0) {
				return 0.00;
			}

			if (this.calculateTotalProducts <= 0) {
				return 0.00;
			}

			return (this.additionalTotalCost / this.calculateTotalProducts).toFixed(2);
		},

		showReason(){
			var result = this.viewDetails && this.editedItem.statusId===this.$Const.STATUS.CANCELLED;
			return result;
		},
	},

	created() {
		this.listOfProviders();
		this.toList();
  },

	methods: {
		async toList(){
			let me=this;
			try {
        me.SHOW_LOADING()
				me.purchases=[];
				await me.getObjectResponse('api/CompanyPurchase/GetAll', me.baseRequest).then(data => {
					if (data !== undefined) {
						me.purchases = me.addCustomProperties(data.purchases);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		addCustomProperties(originalPurchases) {
			let purchases = originalPurchases;
			purchases.map(x => {
				x.showDateTimeDialog = false;
				x.dateTimeItems = [];
			});
			return purchases;
		},

		async listOfProviders(){
      let me=this;
			var providersArray=[];
			await me.getObjectResponse('api/Provider/Select', me.baseRequest).then(data => {
				if (data !== undefined) {
					providersArray = data.providers; 
					providersArray.map(function(x){
            me.providers.push({text:x.visualName, value:x.id});
          });
				}
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},
		
		async fetchProduct(){
			let me=this;
			if (!me.productCodeIsValid) {
				return;
			}
			if (me.details.some(x => x.customCode == me.productCode)) {
				me.$swal(me.swalConfig.errorTitle, 'El producto ya se encuentra agregado.', 'error');
				return;
			}
			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'code': me.productCode,
				};
				await me.getObjectResponse('api/Product/GetByCode', request).then(data => {
					if (data !== undefined) {
						me.addDetail(data.product);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		async updateProduct(item){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				const lastImage = item.image;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'code': item.customCode,
				};
				await me.getObjectResponse('api/Product/GetByCode', request).then(data => {
					if (data !== undefined) {
						me.updateDetail(data.product, lastImage);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		openProductFinder(){
			this.showProductFinder=true;
    },

    closeProductFinder() {
			this.showProductFinder=false;
			this.productCode='';
    },

		addDetail(data = []){
			if (this.details.some(x => x.productId == data['id'])) {
				this.$swal(this.swalConfig.errorTitle, 'El producto ya se encuentra agregado.', 'error');
				return;
			}
			
			let stockFormId = parseInt(data['stockFormId'], 10)
			let stockDetails = this.parseStockDetails(data['stockDetails'], stockFormId, false)
			this.details.push({
				productId: data['id'],
				image: data['image'], 
				customCode: data['customCode'], 
				product: data['visualName'], 
				quantity: 0, 
				unitCost: data['purchasePricePerUnit'] == 0 ? "1" : data['purchasePricePerUnit'],
				totalCost: 0,

				stockFormId: stockFormId,
				stockDetails: stockDetails
			});
			this.productCode='';
		},

		updateDetail(data = [], image) {
			let me=this;
			let stockFormId = parseInt(data['stockFormId'], 10)
			let stockDetails = me.parseStockDetails(data['stockDetails'], stockFormId, false)
			const newItem = {
				productId: data['id'], 
				image: image,
				customCode: data['customCode'], 
				product: data['visualName'], 
				quantity: 0, 
				unitCost: data['purchasePricePerUnit'] == 0 ? "1" : data['purchasePricePerUnit'],
				totalCost: 0,

				stockFormId: stockFormId,
				stockDetails: stockDetails
			};

			const index = me.details.findIndex((x) => x.productId === newItem.productId);
			me.details.splice(index, 1, newItem);
		},

		parseStockDetails(stockDetails, stockFormId, serialize) {
			if (serialize) {
				if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
					return null;
				}
				const stockDetailsResult = stockDetails.filter(f => f.stock !== null && f.stock && parseInt(f.stock) > 0).map(x => x);
				var result = JSON.stringify(stockDetailsResult)
				return result;
			} else {
				if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
					return [];
				}
				var result = JSON.parse(stockDetails)
				if (this.viewDetails) {
					return result;
				}
			
				for (let i = 0; i < result.length; i++) {
					const detail = result[i];
					detail.stock = 0
				}
				return result;
			}
		},

		canEditStock(stockFormId) {
			if (this.viewDetails) {
				return false;
			} else if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
				return true;
			} else {
				return false;
			}
		},

		getStockSum(item){
			if (this.viewDetails) {
					return item.quantity;
			}

			let total = 0;
			const stockArray = item.stockDetails.filter(f => f.stock !== null && f.stock).map(x => parseInt(x.stock))
			if (stockArray.length > 0) {
				const sum = stockArray.reduce((a,b) => a+b)
				total += sum	
			}
			item.quantity = total;
			return total;
		},

		removeDetail(item){
			const index = this.details.indexOf(item);
      if (index !== -1) {
				this.details.splice(index, 1);
			}
		},

		removeDetailFromFinder(data = []){
			const index = this.details.findIndex(x => x.productId == data['id']);
      if (index !== -1) {
				this.details.splice(index, 1);
			}
		},

		setDetails(details){
			var result = [];
			for (let i = 0; i < details.length; i++) {
				const element = details[i];
				var stockFormId = parseInt(element.stockFormId, 10)
				var stockDetails = this.parseStockDetails(element.stockDetails, stockFormId, false)
				result.push({
					productId: element.productId,
					image: null,
					customCode: element.customCode,
					product: element.product,
					quantity: parseInt(element.quantity, 10), 
					originalUnitCost: parseFloat(element.originalUnitCost),
					additionalUnitCost: parseFloat(element.additionalUnitCost),
					unitCost: parseFloat(element.unitCost),
					totalCost: parseFloat(element.totalCost),

					stockFormId: stockFormId,
					stockDetails: stockDetails,
				});
			}
			return result;
		},

		getDetails(){
			var result = [];
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				var stockFormId = parseInt(element.stockFormId, 10)
				var stockDetails = this.parseStockDetails(element.stockDetails, stockFormId, true)
				result.push({
					productId: element.productId, 
					quantity: parseInt(element.quantity, 10), 
					unitCost: parseFloat(element.unitCost),
					stockDetails: stockDetails,
				});
			}
			return result;
		},

		closeStockFormDetails(isExpanded, productId) {
			if (this.viewDetails) {
				return !isExpanded;
			}
			
			if (!this.$refs[`StockForm${productId}`].validate()) {
				return isExpanded;
			}
			return !isExpanded;
		},

		validateAllStockFormDetails(){
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				const formName = `StockForm${element.productId}`;
				var form = this.$refs[formName];
				if (form) {
					if (!form.validate()) {
						return false;
					}
				}
			}
			return true;
		},

		async viewItem(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getObjectResponse('api/CompanyPurchase/GetById', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.viewDetails=true;
					me.editedItem = Object.assign({}, data.purchase);
					me.details = me.setDetails(data.purchase.details);
					me.additionalCostDetails = me.setAdditionalCostDetails(data.purchase.additionalCosts);
					me.paymentData = Object.assign({}, 
					{
						paymentTypeId: data.purchase.paymentTypeId,
						amountPaid: data.purchase.amountPaid,
						cardNumber: data.purchase.cardNumber,
						nextPaymentDate: data.purchase.nextPaymentDate
					});
					me.step++;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    close() {
			this.step=1;
			this.clean();
    },
    
    clean(){
			this.$refs.DtDetails.collapseAll();
			this.$refs.PurchaseForm.reset();
			this.$refs.PaymentForm.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
			this.productCode="";
			this.details = [];
			this.viewDetails=false;

			this.editedAdditionalCosts = Object.assign({}, this.defaultAdditionalCosts);
			if (this.$refs.AdditionalCostsForm) {
				this.$refs.AdditionalCostsForm.reset();	
			}
			this.additionalCostDetails = [];
    },

    async save() {
			let me=this;
			if (me.details.length===0) {
				return;
			}

			if (!me.validateAllStockFormDetails()) {
				return;
			}

			try {
        me.SHOW_LOADING()
        me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'providerId': me.editedItem.providerId,
					'customCode': me.editedItem.customCode,
					'voucherTypeId': me.editedItem.voucherTypeId,
					'voucherNumber': me.editedItem.voucherNumber,
					'authorizationNumber': me.editedItem.authorizationNumber,
					'paymentTypeId': parseInt(me.paymentData.paymentTypeId, 10),
					'amountPaid': parseFloat(me.paymentData.amountPaid),
					'cardNumber': me.paymentData.cardNumber,
					'nextPaymentDate': me.paymentData.nextPaymentDate,
					'creditPaymentTypeId': parseInt(me.paymentData.creditPaymentTypeId),
					'observations': me.editedItem.observations,
					'generateVoucher': me.editedItem.generateVoucher,
					'details': me.getDetails(),
					'additionalCosts': me.getAdditionalCostDetails(),
					'prorate': me.editedItem.prorate
				};
				await me.getObjectResponse('api/CompanyPurchase/Create', request).then(data => {
					if (data === undefined) {
						return;
					}

					me.addOrUpdateListItem(data.purchase)
					if (me.editedItem.generateVoucher && data.fileContent != null) {
						me.reportFile.title = `Compra ${data.purchase.id}`;
						me.reportFile.purchaseId = data.purchase.id;
						me.reportFile.fileName = data.fileName;
						me.reportFile.fileContent = data.fileContent;
						me.showPdfViewer=true;
					}	else {
						me.close();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
		},
		
		addOrUpdateListItem(item){
			let me=this;
			item['showDateTimeDialog'] = false;
			item['dateTimeItems'] = [];
			if (!!me.purchases.find((x) => x.id === item.id)) {
				const index = me.purchases.findIndex((x) => x.id === item.id)
				me.purchases.splice(index, 1, item)
			} else {
				me.purchases.push(item);
			}
		},

		newAdditionalCost(){
			if (!this.$refs.AdditionalCostsForm.validate()) {
				return;
			}
			this.additionalCostDetails.push({ description: this.editedAdditionalCosts.description, cost: this.editedAdditionalCosts.cost, canEdit: true })
			this.$refs.AdditionalCostsForm.reset();
			this.editedAdditionalCosts = Object.assign({}, this.defaultAdditionalCosts);
			this.editedItem.hasAdditionalCosts = true;
		},

		removeAdditionalCost(item){
			const index = this.additionalCostDetails.indexOf(item);
      if (index !== -1) {
				this.additionalCostDetails.splice(index, 1);
			}

			if (this.additionalCostDetails.length <= 0) {
				this.editedItem.hasAdditionalCosts = false;
				this.editedItem.prorate = false;
			}
		},

		getNewUnitCost(originalUnitCost) {
			if (originalUnitCost == null || originalUnitCost == undefined) {
				return 0;
			}
			var total = parseFloat(originalUnitCost) + parseFloat(this.additionalUnitCost);
			return total.toFixed(2);
		},

		setAdditionalCostDetails(additionalCosts){
			var result = [];
			if (!this.editedItem.hasAdditionalCosts) {
				return result;
			}
			for (let i = 0; i < additionalCosts.length; i++) {
				const element = additionalCosts[i];
				result.push(element);
			}
			return result;
		},

		getAdditionalCostDetails(){
			var result = [];
			if (!this.editedItem.hasAdditionalCosts) {
				return result;
			}

			for (let i = 0; i < this.additionalCostDetails.length; i++) {
				const element = this.additionalCostDetails[i];
				result.push({
					description: element.description.trim(), 
					cost: parseFloat(element.cost)
				});
			}
			return result;
		},

		async openPurchaseActionDialog(item, nextStatusId){
			let me = this;
			me.editedPurchase.id = item.id;
			me.editedPurchase.nextStatusId = nextStatusId;
			switch (nextStatusId) {
				case me.$Const.STATUS.CANCELLED:
					me.editedPurchase.title = 'Anular la compra';
					me.showPurchaseActionDialog = true;
					break;
				case me.$Const.STATUS.APPROVED:
					await me.approve();
					break;
			}
		},

		closePurchaseActionDialog(){
			this.showPurchaseActionDialog = false;
			this.$refs.PurchaseActionForm.reset();
      this.editedPurchase = Object.assign({}, this.defaultPurchase);
		},
		
		async savePurchaseAction(){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.loadingPurchaseActionForm = true;
				switch (me.editedPurchase.nextStatusId) {
					case me.$Const.STATUS.CANCELLED:
						await me.cancel();
						break;
				}
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.loadingPurchaseActionForm = false;
      }
		},

		async cancel(){
			let me=this;
			if (!await me.showConfirmationDialog('Estás a punto de anular la compra, esta acción no será revertida.', 'Sí, anular la compra!')) {
				me.closePurchaseActionDialog();
				return;
			}

			let request={
				'companyId': me.baseRequest.companyId,
				'userId': me.baseRequest.userId,
				'id': me.editedPurchase.id,
				'reason': me.editedPurchase.reason,
			};
			await me.getObjectResponse('api/CompanyPurchase/Cancel', request).then(data => {
				if (data === undefined) {
					return;
				}
				me.addOrUpdateListItem(data.purchase);
				me.closePurchaseActionDialog();
			}).catch(function(error){
				me.$swal(me.swalConfig.errorTitle, error.message, 'error');
			});
		},

		async approve() {
			let me=this;
			if (!await me.showConfirmationDialog('Estás a punto de aprobar la compra, el detalle de productos sera habilitado para las ventas y movimientos posteriores, esta acción no será revertida.', 'Sí, aprobar la compra!')) {
				return;
			}

			try {
				me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': me.editedPurchase.id
				};
				await me.getObjectResponse('api/CompanyPurchase/Approve', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.addOrUpdateListItem(data.purchase);
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
      }
		},

		async openDateTimeDialog(item){
			let me=this;
			try {
        me.SHOW_LOADING()
				item.dateTimeItems=[];
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};

				if(item.statusId==me.$Const.STATUS.CANCELLED)
				{
					me.dateTimeHeaders.push({ text: "Motivo", value: "reason", sortable: false });
				}

				await me.getObjectResponse('api/CompanyPurchase/GetDatesById', request).then(data => {
					if (data !== undefined) {
						item.dateTimeItems = data.dateTimeList;
						item.showDateTimeDialog=true;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    closeDateTimeDialog(item) {
			item.showDateTimeDialog=false;
			item.dateTimeItems=[];
			if(item.statusId==this.$Const.STATUS.CANCELLED)
			{
				const index = this.dateTimeHeaders.indexOf(x => x.value == "reason");
				this.dateTimeHeaders.splice(index, 1);
			}
    },
		
		async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/CompanyPurchase/GetMainReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = '100 Últimas Compras';
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async reportView(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getObjectResponse('api/CompanyPurchase/GetReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = `Compra ${me.reportFile.purchaseId}`;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
			if (this.step===2) {
				this.close();
			}
		},
	},
}
</script>